.dynamic-file-mapping-gui {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

header {
  background-color: #3498db;
  color: white;
  padding: 20px;
  text-align: center;
  border-radius: 5px 5px 0 0;
}

h1, h2, h3 {
  margin: 0;
  font-weight: 600;
}

nav {
  background-color: #2980b9;
  padding: 10px;
  border-radius: 0 0 5px 5px;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
}

nav li {
  color: white;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

nav li:hover {
  background-color: #1c638e;
}

nav li.active {
  background-color: #1c638e;
  font-weight: bold;
}

main {
  background-color: white;
  padding: 20px;
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.error-message {
  background-color: #e74c3c;
  color: white;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.step-content {
  margin-top: 20px;
}

.file-upload-container, .column-selection, .mapping-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 20px;
}

.file-upload, .select-container, .mapping-section, .summary-section {
  flex: 1;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: #333;
}

select, input[type="file"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.data-preview, .data-sheet {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.mapping-list {
  max-height: 300px;
  overflow-y: auto;
}

.mapping-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.summary-table {
  margin-top: 20px;
}

.summary-column-select {
  margin-bottom: 10px;
}

.data-sheet input {
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.btn-primary {
  background-color: #3498db;
  color: white;
}

.btn-primary:hover {
  background-color: #2980b9;
}

footer {
  margin-top: 20px;
  text-align: center;
  color: #777;
  font-size: 14px;
}